<template>
  <v-card>
    <v-card-title>経路テーブル</v-card-title>
    <v-text-field v-model="search" label="経路マスタ内文字検索" append-icon="mdi-magnify"></v-text-field>
  <v-data-table :headers="headers" :items="items" :items-per-page="15" loading-text="読込中" :search="search" :custom-filter="filterOnlyCapsText"/>
 </v-card>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      search: '',
      items: [],
      headers: [
          { text: 'ID',  value: 'Id'},
          { text: '開始経路', value: 'YAKRMstSchi'},
          { text: '終了経路', value: 'YAKRMstEchi'},
          { text: '高速区分', value: 'YAKRMstKS'},
          { text: '距離km', value: 'YAKRMstKm'},
      ],
    }
  },
  //経路取得
  mounted: function() {
    axios
      .get('/api/YAKRMstALL')
      .then(response => {
        this.items = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
   methods: {
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
  },
}
</script>